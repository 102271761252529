import React from "react";
import "./blocking.scss";

const Blocking = () => {
	return (
		<section className="full-width blocking">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Блокировка</h2>
					</div>

					<div className="blocking__info col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<p>
							Блокировка Льготной транспортной карты осуществляется по звонку
							Клиента (временная блокировка) в Банк либо по письменному
							заявлению на блокировку Льготной транспортной карты. Блокировка
							Карты по телефонному звонку осуществляется при условии
							идентификации Клиента по кодовому слову.
						</p>
						<p>
							Перевыпуск Льготной транспортной карты осуществляется только при
							блокировке ранее выпущенной Льготной транспортной карты.
						</p>
					</div>
				</div>
			</div>
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-10">
						<h4>Временная блокировка</h4>
						<section className="phone-block">
							<a href={`tel:+7 (843) 518-12-21`}>+7 (843) 518-12-21</a>
							<a href={`tel:+7 (843) 519-38-85`}>+7 (843) 519-38-85</a>
							<div className="phone-block__work-time">
								<p>8.30 – 19.00 в будни</p>
								<p>9.00 — 17.00 в выходные</p>
							</div>
						</section>
					</div>
					<div className="col-xs-12 col-md-4 col-sm-offset-1 col-sm-10">
						<h4>Ночная экстренная блокировка</h4>
						<section className="phone-block">
							<a href={`tel:+7 (843) 519-38-85`}>+7 (843) 519-38-85</a>
							<div className="phone-block__work-time">
								<p>19:00 — 08:30 в будни</p>
								<p>16:00 — 08:30 в выходные</p>
							</div>
						</section>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Blocking;
